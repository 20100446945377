<template>
  <v-row class="ma-0" justify="center">
    <!-- loading -->
    <v-progress-circular v-if="loading" :size="15" color="main" indeterminate />

    <template v-else>
      <!-- edit request dialog -->
      <v-dialog v-model="showDialog"
                @click:outside="closeHandler"
                @keydown="(value) => value.key === 'Escape' ? closeHandler() : ''"
                scrollable max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showDialog = true">mdi-square-edit-outline</v-icon>
        </template>

        <v-card class="pa-1">
          <!-- title -->
          <v-card-title class="d-flex justify-center">
            <h3 class="main--text">{{ translations.editRequest }}</h3>
          </v-card-title>

          <v-card-text>
            <!-- vehicle -->
            <v-col class="pa-0">
              <b>{{ translations.equipment }}</b>

              <p class="mb-0 mt-1">
                {{ `${item.vehicle.code} - ${item.vehicle.label}` }}
              </p>
            </v-col>

            <!-- type -->
            <v-col class="pa-0 mt-6">
              <b>{{ translations.activityType }}:</b>

              <v-radio-group v-model="type" class="pa-0 ma-0" hide-details>
                <v-radio v-for="vehicleStatus in $store.getters.vehicleStatuses" :key="vehicleStatus.value"
                         :value="vehicleStatus.value" :label="vehicleStatus.text"
                         hide-details dense class="mt-2">
                </v-radio>
              </v-radio-group>
            </v-col>

            <!-- date from -->
            <v-col class="pa-0 mt-6">
              <b>{{ translations.dateFrom }}</b>

              <date-picker @selected-date="(value) => setDate(value, 'dateFrom')"
                           :dateProp="dateFrom"
                           :classToAdd="['date-picker-component']"
                           :disablePicker="false">
              </date-picker>
            </v-col>

            <!-- date to -->
            <v-col class="pa-0 mt-6">
              <b>{{ translations.toDate }}</b>

              <date-picker @selected-date="(value) => setDate(value, 'dateTo')"
                           :dateProp="dateTo"
                           :minDateProp="dateFrom.picker"
                           :startToDate="startToDate"
                           :classToAdd="['date-picker-component']"
                           :disablePicker="false">
              </date-picker>
            </v-col>

            <v-col class="pa-0 mt-6">
              <b>{{ translations.optionalNoteForRequest }}:</b>

              <v-textarea v-model="note" filled flat dense hide-details></v-textarea>
            </v-col>

            <!-- created by -->
            <v-col v-if="item.account !== null" class="pa-0 mt-6">
              <b>{{ translations.createdBy }}</b>

              <p class="mb-0 mt-1">
                {{ `${item.account.name} - ${item.createdAtStr}` }}
              </p>
            </v-col>
          </v-card-text>

          <!-- invalid form message -->
          <v-row v-if="displayDateError" class="pa-0 mx-0 mt-2" justify="center" align="center">
            <v-icon color="error" class="mr-2" small>mdi-alert</v-icon>

            <span class="error--text" style="font-size: .9rem">{{ translations.fromToDateError }}</span>
          </v-row>

          <v-card-actions class="pb-4 pt-4">
            <v-row class="ma-0" justify="space-between">
              <v-col class="pa-0" cols="auto">
                <v-btn v-if="item.status !== 'REJECTED'" @click="changeVehicleRequest('REJECTED')" color="error" class="mr-2" text depressed>{{ translations.deny }}</v-btn>
              </v-col>

              <v-col class="pa-0" cols="auto">
                <v-btn v-if="item.status === 'PENDING'" @click="updateVehicleRequest('PENDING')" outlined color="main" class="px-8 mr-2" :loading="loading" :disabled="disableBtn">{{ translations.save }}</v-btn>

                <v-btn @click="updateVehicleRequest('APPROVED')" color="main" class="white--text px-8" :loading="loading" :disabled="disableBtn">{{ item.status === 'APPROVED' ? translations.edit : translations.saveAndApprove }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- delete -->
      <v-icon @click="deleteVehicleRequest"
              small color="error lighten-1" class="cursor-pointer">
        mdi-delete-outline
      </v-icon>


      <!-- message dialog -->
      <message-dialog :show="showErrorMsgDialog">
        <template v-slot:card-image>
          <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
            <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
          </div>
        </template>

        <template v-slot:message>
          <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
            {{ translations.overlappingDatesError }}
          </div>
        </template>

        <template v-slot:card-action>
          <div class="d-flex justify-center cursor-pointer" style="width: 100%">
            <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
          </div>
        </template>
      </message-dialog>
    </template>
  </v-row>
</template>


<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import VehicleRequestService from '@/service/vehicleRequest/VehicleRequestService'

import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'VehicleRequestEdit',

  components: {
    DatePicker,
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      type: this.item.type,
      dateFrom: {
        simple: this.item.dateFromStr,
        holder: this.momentDate(this.item.dateFromStr, 'DD/MM/YYYY'),
        picker: this.item.dateFromStr
      },
      dateTo: {
        simple: this.item.dateToStr,
        holder: this.momentDate(this.item.dateToStr, 'DD/MM/YYYY'),
        picker: this.item.dateToStr
      },
      startToDate: this.item.dateFromStr,
      note: this.item.note
    }
  },

  computed: {
    ...mapState({
      accountId: state => state.auth.userInfo.account.id
    }),

    dateFromIsAfterTo() {
      if (!this.dateFrom.simple || !this.dateTo.simple) return true

      return this.dateFrom.simple > this.dateTo.simple
    },

    displayDateError() {
      return this.dateFrom.simple && this.dateTo.simple && this.dateFromIsAfterTo
    },

    disableBtn() {
      return !this.type || !this.dateFrom.simple || !this.dateTo.simple || this.dateFromIsAfterTo
    }
  },

  watch: {
    showDialog: function(newVal) {
      this.note = this.item.note
      this.type = this.item.type
      if (newVal) {
        this.dateFrom = {
          simple: this.item.dateFromStr,
          holder: this.momentDate(this.item.dateFromStr, 'DD/MM/YYYY'),
          picker: this.item.dateFromStr
        }
        this.dateTo = {
          simple: this.item.dateToStr,
          holder: this.momentDate(this.item.dateToStr, 'DD/MM/YYYY'),
          picker: this.item.dateToStr
        }
      }
    }
  },

  mixins: [MomentMixin],

  methods: {
    setDate(value, type) {
      this[type].simple = value.simple
      this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
      this[type].picker = this.formatDate(value.simple)

      if (type === 'dateFrom') {
        this.startToDate = value.picker
      }
    },

    closeHandler() {
      this.dateFrom = {
        simple: this.item.dateFromStr,
        holder: this.momentDate(this.item.dateFromStr, 'DD/MM/YYYY'),
        picker: this.item.dateFromStr
      }
      this.dateTo = {
        simple: this.item.dateToStr,
        holder: this.momentDate(this.item.dateToStr, 'DD/MM/YYYY'),
        picker: this.item.dateToStr
      }
    },

    async updateVehicleRequest(status) {
      this.loading = true
      await VehicleRequestService.save({
          vehicleRequestObject: {
            id: this.item.id,
            accountId: this.accountId,
            vehicleId: this.item.vehicle.id,
            type: this.type,
            dateFromStr: this.dateFrom.simple,
            dateToStr: this.dateTo.simple,
            note: this.note,
            status: status
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    async changeVehicleRequest(action) {
      this.loading = true
      await VehicleRequestService.change({
          vehicleRequestId: this.item.id,
          accountId: this.accountId,
          status: action,
          note: this.item.note
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    async deleteVehicleRequest() {
      this.loading = true
      await VehicleRequestService.delete({
          vehicleRequestId: this.item.id,
          accountId: this.accountId
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
