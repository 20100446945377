<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="VehicleRequest">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1>
        <vehicle-request-new @updated="$refs.smartTable.getTableRows()" />
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- code -->
        <td>{{ item.vehicle.code }} - {{ item.vehicle.label }}</td>

        <!-- type -->
        <td>
          {{ translationsUtil('vehicleStatus_' + item.type) }}
        </td>

        <!-- date from -->
        <td>
          {{ item.dateFromStr ? momentDate(item.dateFromStr) : '-' }}
        </td>

        <!-- date to -->
        <td>
          {{ item.dateToStr ? momentDate(item.dateToStr) : '-' }}
        </td>

        <!-- status -->
        <td>
          <v-tooltip top color="black" :disabled="!item.note || item.note.length === 0">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" small label class="status-chip mr-2"
                      :color="$store.getters.vehicleRequestStatuses[item.status].background">
                <div :style="[{ color: $store.getters.vehicleRequestStatuses[item.status].color }]">
                  {{ $store.getters.vehicleRequestStatuses[item.status].text }}
                  <span v-if="item.note && item.note.length > 0">*</span>
                </div>
              </v-chip>
            </template>
            {{ item.note }}
          </v-tooltip>
        </td>

        <!-- created by -->
        <td>
          {{ item.account === null ? '' : item.account.name }}
        </td>

        <!-- created at -->
        <td>
          {{ formatFETimestamp(item.createdAtStr) }}
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around">
            <vehicle-request-edit :item="item" @updated="$refs.smartTable.getTableRows()" />
          </div>
        </td>
      </template>
    </smart-table>
  </div>
</template>


<script>
import { translations } from '@/utils/common'
import translationsUtil from '@/utils/common/translationsUtil'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import VehicleRequestService from '@/service/vehicleRequest/VehicleRequestService'

import SmartTable from '@/components/SmartTable'
import VehicleRequestEdit from '@/views/VehicleRequest/VehicleRequestEdit'
import VehicleRequestNew from '@/views/VehicleRequest/VehicleRequestNew'

export default {
  name: 'VehicleRequest',

  components: {
    SmartTable,
    VehicleRequestNew,
    VehicleRequestEdit
  },

  data() {
    return {
      translations: translations,
      translationsUtil: translationsUtil
    }
  },

  mixins: [MomentMixin],

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'code',
        label: translations.code,
        sort: 'vehicle.code'
      })

      headers.push({
        type: 'SELECT',
        name: 'type',
        label: translations.activityType,
        selectItems: this.$store.getters.vehicleStatuses,
        sort: 'type'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateFromStr',
        label: translations.date + ' (' + translations.from + ')',
        sort: 'dateFrom'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'dateToStr',
        label: translations.date + ' (' + translations.to + ')',
        sort: 'dateTo'
      })

      headers.push({
        type: 'SELECT',
        name: 'statuses',
        label: translations.status,
        selectItems: this.$store.getters.vehicleRequestStatusesSelect,
        sort: 'status'
      })

      headers.push({
        type: 'LABEL',
        name: 'createdBy',
        label: translations.createdBy,
        sort: 'account.name'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'createdAtStr',
        label: translations.createdAt,
        sort: 'createdAt'
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      return VehicleRequestService.search(params)
    }
  }
}
</script>
