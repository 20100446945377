var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('smart-table',{ref:"smartTable",attrs:{"headers":_vm.headers(),"search-function":_vm.searchFunction,"preference-key":"ActivityRequest"},scopedSlots:_vm._u([{key:"HEADER-ITEM-1",fn:function(){return [_c('activity-request-new',{on:{"updated":function($event){return _vm.$refs.smartTable.getTableRows()}}})]},proxy:true},{key:"results",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-tooltip',{attrs:{"disabled":!(item.employee.employmentEndDateStr < _vm.today),"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:(item.employee.employmentEndDateStr < _vm.today ? 'color:red;font-style:italic' : '')},'span',attrs,false),on),[_vm._v(_vm._s(item.employee.name))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.translations.employeeNotActiveRequestExplain)+": "+_vm._s(item.employee.employmentEndDateStr)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(item.employeeStatus.description)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.momentDate(item.dateFromStr))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.momentDate(item.dateToStr))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$store.getters.activityRequestDayPartsMap[item.dayPart])+" ")]),_c('td',[_c('v-tooltip',{attrs:{"top":"","color":"black","disabled":!item.note || item.note.length === 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"status-chip mr-2",attrs:{"small":"","label":"","color":_vm.$store.getters.activityRequestStatuses[item.status].background}},'v-chip',attrs,false),on),[_c('div',{style:([{ color: _vm.$store.getters.activityRequestStatuses[item.status].color }])},[_vm._v(" "+_vm._s(_vm.$store.getters.activityRequestStatuses[item.status].text)+" "),(item.status === 'REJECTED' && item.note && item.note.length > 0)?_c('span',[_vm._v("*")]):_vm._e()])])]}}],null,true)},[_vm._v(" "+_vm._s(item.note)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.formatFETimestamp(item.createdAtStr))+" ")]),_c('td',{attrs:{"width":"100"}},[_c('div',{staticClass:"d-flex justify-space-around"},[_c('activity-request-edit',{attrs:{"item":item},on:{"updated":function($event){return _vm.$refs.smartTable.getTableRows()}}})],1)])]}},{key:"FOOTER-ITEM-1",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"main"},on:{"click":_vm.reportActivityRequests}},'v-icon',attrs,false),on),[_vm._v("mdi-printer")])]}}])},[_vm._v(" "+_vm._s(_vm.translations.exportList)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }