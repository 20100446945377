<template>
  <v-row class="ma-0" justify="center">
    <!-- loading -->
    <v-progress-circular v-if="loading" :size="15" color="main" indeterminate />

    <template v-else>
      <!-- edit request dialog -->
      <v-dialog v-model="showDialog"
                @click:outside="closeHandler"
                @keydown="(value) => value.key === 'Escape' ? closeHandler() : ''"
                scrollable max-width="700px">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-on="on" v-bind="attrs" small class="mr-2" @click="showDialog = true">mdi-square-edit-outline</v-icon>
        </template>

        <v-card class="pa-1">
          <!-- title -->
          <v-card-title class="d-flex justify-center">
            <h3 class="main--text">{{ translations.editRequest }}</h3>
          </v-card-title>

          <v-card-text>
            <!-- employee -->
            <v-col class="pa-0">
              <b>{{ translations.employee }}</b>

              <p class="mb-0 mt-1">
                {{ `${item.employee.name}` }}
              </p>
            </v-col>

            <!-- note -->
            <v-col class="pa-0 mt-6">
              <b>{{ translations.additionalInfo }} *</b>

              <v-textarea v-model="note" filled flat dense hide-details />
            </v-col>

            <!-- status -->
            <v-col class="pa-0 mt-6">
              <b>{{ translations.status }} *</b>

              <v-select v-model="status" :items="$store.getters.dpiStatusesSelect" outlined dense hide-details max-width="180px" />
            </v-col>

            <!-- created at -->
            <v-col class="pa-0 mt-6">
              <b>{{ translations.createdAt }}</b>

              <p class="mb-0 mt-1">
                {{ `${formatFETimestamp(item.createdAtStr)}` }}
              </p>
            </v-col>

            <!-- last update -->
            <v-col class="pa-0 mt-5">
              <b>{{ translations.lastUpdate }}</b>

              <p class="mb-0 mt-1">
                {{ `${item.lastUpdateBy}` }} - {{ `${formatFETimestamp(item.lastUpdateStr)}` }}
              </p>
            </v-col>
          </v-card-text>

          <v-card-actions class="pb-4 pt-4">
            <v-row class="ma-0" justify="center">
              <v-btn @click="updateDPI" :disabled="disableBtn" color="main" class="white--text px-8" :loading="loading">{{ translations.confirm }}</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- delete -->
      <v-icon @click="deleteDPI"
              small color="error lighten-1" class="cursor-pointer">
        mdi-delete-outline
      </v-icon>


      <!-- message dialog -->
      <message-dialog :show="showErrorMsgDialog">
        <template v-slot:card-image>
          <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
            <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
          </div>
        </template>

        <template v-slot:message>
          <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
            {{ translations.overlappingDatesError }}
          </div>
        </template>

        <template v-slot:card-action>
          <div class="d-flex justify-center cursor-pointer" style="width: 100%">
            <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
          </div>
        </template>
      </message-dialog>
    </template>
  </v-row>
</template>


<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import DpiService from '@/service/dpi/DpiService'

import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'DpiNew',

  components: {
    MessageDialog,
    WarningSvg
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      status: this.item.status,
      note: this.item.note
    }
  },

  computed: {
    disableBtn() {
      return !this.status || !this.note
    }
  },

  watch: {
    showDialog: function() {
      this.note = this.item.note
      this.status = this.item.status
    }
  },

  mixins: [MomentMixin],

  methods: {
    closeHandler() {
      this.status = this.item.status
      this.note = this.item.note
    },

    async updateDPI() {
      this.loading = true
      await DpiService.save({
          dpiDTO: {
            id: this.item.id,
            status: this.status,
            note: this.note
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    async deleteDPI() {
      this.loading = true
      await DpiService.delete({
          dpiId: this.item.id
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.deletedSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
