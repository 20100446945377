<template>
  <v-row class="ma-0" justify="center">
    <!-- new request dialog -->
    <v-dialog v-model="showDialog"
              @click:outside="closeHandler"
              @keydown="value => value.key === 'Escape' ? closeHandler() : ''"
              scrollable max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn @click="showDialog = true" color="main" class="white--text" small depressed v-on="on">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ translations.newButton }}
        </v-btn>
      </template>

      <v-card class="pa-1">
        <!-- title -->
        <v-card-title class="d-flex justify-center">
          <h3 class="main--text">{{ translations.newRequest }}</h3>
        </v-card-title>

        <v-card-text>
          <v-col class="pa-0">
            <!-- vehicle -->
            <b>{{ translations.equipment }}</b>

            <v-autocomplete v-model="vehicleId" :items="vehicleList" item-text="code" item-value="id" :search-input.sync="vehicleToSearch" :placeholder="translations.typeVehicleCode" outlined hide-details dense clearable>
              <template v-slot:selection="data">
                {{ `${data.item.code} - ${data.item.label}` }}
              </template>

              <template v-slot:item="data">
                {{ `${data.item.code} - ${data.item.label}` }}
              </template>

              <template v-slot:append-item>
                <v-row v-if="hasNextVehicles && !$apollo.queries.vehicles.loading" class="ma-0" justify="center">
                  <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" :size="20"></v-progress-circular>
                </v-row>
              </template>

              <template v-slot:no-data>
                <v-list-item>
                  {{ translations.noVehiclesWithThatCode }}
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <!-- type -->
          <v-col class="pa-0 mt-6">
            <b>{{ translations.activityType }}:</b>

            <v-radio-group v-model="type" class="pa-0 ma-0" hide-details>
              <v-radio v-for="vehicleStatus in $store.getters.vehicleStatuses" :key="vehicleStatus.value" :value="vehicleStatus.value" :label="vehicleStatus.text" hide-details dense class="mt-2"></v-radio>
            </v-radio-group>
          </v-col>

          <!-- date from -->
          <v-col class="pa-0 mt-6">
            <b>{{ translations.dateFrom }}</b>

            <date-picker @selected-date="value => setDate(value, 'dateFrom')" :dateProp="dateFrom" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
          </v-col>

          <!-- date to -->
          <v-col class="pa-0 mt-6">
            <b>{{ translations.toDate }}</b>

            <date-picker @selected-date="value => setDate(value, 'dateTo')" :dateProp="dateTo" :minDateProp="dateFrom.picker" :startToDate="startToDate" :classToAdd="['date-picker-component']" :disablePicker="false"></date-picker>
          </v-col>

          <v-col class="pa-0 mt-6">
            <b>{{ translations.optionalNoteForRequest }}:</b>
            <v-textarea v-model="note" filled flat dense hide-details></v-textarea>
          </v-col>
        </v-card-text>

        <!-- invalid form message -->
        <v-row v-if="displayDateError" class="pa-0 mx-0 mt-2" justify="center" align="center">
          <v-icon color="error" class="mr-2" small>mdi-alert</v-icon>

          <span class="error--text" style="font-size: .9rem">{{ translations.fromToDateError }}</span>
        </v-row>

        <v-card-actions class="pb-4 pt-4">
          <v-row class="ma-0" justify="center">
            <v-btn @click="saveVehicleRequest" :disabled="disableBtn" color="main" class="white--text px-8" :loading="loading">{{ translations.create }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- message dialog -->
    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <div class="d-flex justify-center cursor-pointer pt-2" @click="showErrorMsgDialog = false">
          <warning-svg :brandColor="$vuetify.theme.themes.light.main" width="200px"></warning-svg>
        </div>
      </template>

      <template v-slot:message>
        <div class="d-flex justify-center cursor-pointer pt-1" @click="showErrorMsgDialog = false">
          {{ translations.overlappingDatesError }}
        </div>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-center cursor-pointer" style="width: 100%">
          <v-btn color="main" class="white--text" text @click="showErrorMsgDialog = false">{{ translations.ok }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import VehicleRequestService from '@/service/vehicleRequest/VehicleRequestService'

import vehiclesQuery from '@/graphql/Vehicles.gql'

import DatePicker from '@/components/DatePicker'
import MessageDialog from '@/components/MessageDialog'
import WarningSvg from '@/assets/WarningSvg'

export default {
  name: 'VehicleActivityNew',

  components: {
    DatePicker,
    MessageDialog,
    WarningSvg
  },

  apollo: {
    vehicles: {
      query: vehiclesQuery,
      variables() {
        return {
          page: this.page,
          pageSize: 20,
          sort: 'code',
          order: 'asc',
          name: this.vehicleToSearch
        }
      },
      fetchPolicy: 'no-cache',
      result({ data }) {
        const { content, hasNext } = data.vehicles

        this.vehicleList.push(...content)
        this.hasNextVehicles = hasNext
      }
    }
  },

  data() {
    return {
      translations: translations,

      showDialog: false,
      loading: false,
      showErrorMsgDialog: false,

      type: this.$store.getters.vehicleStatuses[0].value,
      dateFrom: {
        simple: '',
        holder: '',
        picker: ''
      },
      dateTo: {
        simple: '',
        holder: '',
        picker: ''
      },
      vehicleId: '',
      vehicleToSearch: '',
      page: 1,
      hasNextVehicles: false,
      startToDate: '',
      note: '',
      vehicleList: []
    }
  },

  computed: {
    ...mapState({
      accountId: state => state.auth.userInfo.account.id
    }),

    dateFromIsAfterTo() {
      if (!this.dateFrom.simple || !this.dateTo.simple) return true

      return this.dateFrom.simple > this.dateTo.simple
    },

    displayDateError() {
      return this.dateFrom.simple && this.dateTo.simple && this.dateFromIsAfterTo
    },

    disableBtn() {
      return !this.type || !this.dateFrom.simple || !this.dateTo.simple || !this.vehicleId || this.dateFromIsAfterTo
    }
  },

  mixins: [MomentMixin],

  methods: {
    setDate(value, type) {
      if (type === 'both') {
        this.dateFrom.simple = value.simple
        this.dateFrom.holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this.dateFrom.picker = this.formatDate(value.simple)

        this.dateTo.simple = value.simple
        this.dateTo.holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this.dateTo.picker = this.formatDate(value.simple)

        this.startToDate = value.picker
      } else {
        this[type].simple = value.simple
        this[type].holder = this.momentDate(value.simple, 'DD/MM/YYYY')
        this[type].picker = this.formatDate(value.simple)

        if (type === 'dateFrom') {
          this.startToDate = value.picker
        }
      }
    },

    closeHandler() {
      this.type = 'REP'
      this.dateFrom = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.dateTo = {
        simple: '',
        holder: '',
        picker: ''
      }
      this.vehicleId = ''
      this.note = ''
    },

    async saveVehicleRequest() {
      this.loading = true
      await VehicleRequestService.save({
          vehicleRequestObject: {
            accountId: this.accountId,
            vehicleId: this.vehicleId,
            note: this.note,
            type: this.type,
            dateFromStr: this.dateFrom.simple ? this.formatDate(this.dateFrom.simple) : null,
            dateToStr: this.dateTo.simple ? this.formatDate(this.dateTo.simple) : null
          }
        })
        .then(() => {
          this.$root.vtoast.show({ message: translations.updateSuccess })
          this.showDialog = false
          this.closeHandler()
          this.$emit('updated')
        })
        .catch(() => {
          this.showErrorMsgDialog = true
        })
        .finally(() => {
          this.loading = false
        })
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.page++
      }
    }
  }
}
</script>
