<template>
  <div>
    <smart-table ref="smartTable"
                 :headers="headers()"
                 :search-function="searchFunction"
                 preference-key="Dpi">

      <!-- smart table slot HEADER-ITEM-1: new dialog (activate in the headers) -->
      <template v-slot:HEADER-ITEM-1>
        <dpi-new @updated="$refs.smartTable.getTableRows()" />
      </template>

      <!-- smart table slot results -->
      <template v-slot:results="{ item: item }">
        <!-- employee name -->
        <td>
          {{ item.employee.name }}
        </td>

        <!-- note -->
        <td>
          {{ item.note }}
        </td>

        <!-- status -->
        <td>
          <v-chip small label class="status-chip mr-2"
                  :color="$store.getters.dpiStatuses[item.status].background">
            <div :style="[{ color: $store.getters.dpiStatuses[item.status].color }]">
              {{ $store.getters.dpiStatuses[item.status].text }}
            </div>
          </v-chip>
        </td>

        <!-- created at -->
        <td>
          {{ formatFETimestamp(item.createdAtStr) }}
        </td>

        <!-- last update by -->
        <td>
          {{ item.lastUpdateBy }}
        </td>

        <!-- last update -->
        <td>
          {{ formatFETimestamp(item.lastUpdateStr) }}
        </td>

        <!-- actions -->
        <td width="100">
          <div class="d-flex justify-space-around">
            <dpi-edit :item="item" @updated="$refs.smartTable.getTableRows()" />
          </div>
        </td>
      </template>
    </smart-table>
  </div>
</template>

<script>
import { translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin'

import DpiService from '@/service/dpi/DpiService'

import SmartTable from '@/components/SmartTable'
import DpiNew from '@/views/Dpi/DpiNew'
import DpiEdit from '@/views/Dpi/DpiEdit'

export default {
  name: 'Dpi',

  components: {
    SmartTable,
    DpiNew,
    DpiEdit
  },

  data() {
    return {
      translations: translations
    }
  },

  mixins: [MomentMixin],

  methods: {
    headers() {
      const headers = []

      headers.push({
        type: 'TEXT',
        name: 'employeeName',
        label: translations.employee,
        sort: 'employee.name'
      })

      headers.push({
        type: 'TEXT',
        name: 'note',
        label: translations.notes,
        sort: 'note'
      })

      headers.push({
        type: 'SELECT',
        name: 'statuses',
        label: translations.status,
        selectItems: this.$store.getters.dpiStatusesSelect,
        sort: 'status'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'createdAtStr',
        label: translations.createdAt,
        sort: 'createdAt'
      })

      headers.push({
        type: 'TEXT',
        name: 'lastUpdateBy',
        label: translations.lastUpdateBy,
        sort: 'lastUpdateBy'
      })

      headers.push({
        type: 'DATE-PICKER',
        name: 'lastUpdateStr',
        label: translations.lastUpdateAt,
        sort: 'lastUpdate'
      })

      // actions
      headers.push({
        type: 'HEADER-ITEM-1'
      })

      return headers
    },

    searchFunction(params) {
      return DpiService.search(params)
    }
  }
}
</script>
