import { render, staticRenderFns } from "./VehicleRequest.vue?vue&type=template&id=6ff4cd66&"
import script from "./VehicleRequest.vue?vue&type=script&lang=js&"
export * from "./VehicleRequest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VTooltip})
